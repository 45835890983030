.Footer-container {
    position: relative;
}

.Footer-container > hr {
    border: 1px solid var(--lightgray);
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* padding: 1rem 2rem; */
    height: 20rem;
    gap: 1rem;
}

.social-links {
    display: flex;
    /* gap: 4rem; */
}

/* .social-links > img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
} */

.logo-f > img {
    width: 10rem
}

.blur-f-1 {
    bottom: 0;
    right: 15%;
    width: 10rem;
    height: 10rem;
    filter: blur(200px);
    background: red;
}

.blur-f-2 {
    bottom: 0;
    left: 15%;
    width: 10rem;
    height: 5rem;
    filter: blur(300px);
    background: rgb(255, 115, 0);
}
p{
    color: white;
}
p span{
    color: var(--orange);
    font-weight: 600;
}