:root {
  --lightgray: #D9D9D9;
  --gray: #9c9c9c;
  --orange: #e79f39;
  
  --darkGrey: #464D53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #ffa739 1.14%, rgb(245, 151, 75) 100.75%);
  --appColor:  #4c4e52;
}

.App {
  
  mix-blend-mode: overlay;
  background-color: var(--appColor);
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}
::-webkit-scrollbar{
  display: none;
}

.stroke-text {
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
}

.btn:hover {
  cursor: pointer;
}

.blur {
  background:var(--orange);
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -9;

}